import React, {useEffect, useState} from "react";
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import * as notify from "../../../lib/notify"
import {createEntry, updateEntry} from "../../../service/anti-fraud-config";
import {Controller, useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {showDangerToast} from "../../../lib/notify";
import Switch from "../../generic/Switch";
const {Config: { esb }} = require('lead-generation-common');

function CreateOrEdit(props) {
    const [type, setType] = useState("auto");
    const [project, setProject] = useState(esb.projects[0]);
    const {isOpen, onClose} = props;
    const isCreate = !props.data || !props.data.id;
    const types = ["auto", "allow", "autosuspicious", "manual"];

    const {register, handleSubmit, reset, errors, control} = useForm({
        defaultValues: props.data || {}
    });
    const handleTypeChange = type => setType(type);
    const handleProjectChange = project => setProject(project);

    useEffect(() => {
        reset(props.data);
    }, [props.data])


    function onSave(data) {
        if (props.type === 'config') {
            const { formKey, cellId, verifyFace, afTypeSwitchCount } = data;
            const params = {
                formKey,
                cellId,
                type,
                kind: props.type,
                verifyFace,
                afTypeSwitchCount: parseInt(afTypeSwitchCount, 10) || 0
            }

            if (!formKey) return notify.showWarningToast('All fields are required!');
            if (props.data && props.data.id) updateEntry(props.data.id, params).then(props.onSave).catch(showDangerToast);
            else createEntry(params).then(props.onSave).catch(showDangerToast);
        } else {
            const params = {
                project,
                type,
                kind: props.type
            }

            if (props.data && props.data.id) updateEntry(props.data.id, params).then(props.onSave).catch(showDangerToast);
            else createEntry(params).then(props.onSave).catch(showDangerToast);
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={props.onClose} type={props.type}>
            <ModalHeader>{isCreate ? "Create" : "Update"}</ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit(onSave)}>
                    {
                        props.type === 'config' ? (
                            <>
                                <FormGroup>
                                    <Label for="formKey">Form Key</Label>
                                    <input
                                        name="formKey"
                                        placeholder="Enter form key"
                                        className={`form-control ${errors.formKey ? "is-invalid" : ""}`}
                                        ref={register({
                                            required: "Form Key is required",
                                            pattern: {
                                                message: "Invalid Form Key format"
                                            }
                                        })}
                                    />

                                    {errors.formKey && <div className="invalid-feedback">{errors.formKey.message}</div>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="cellId">Cell ID</Label>
                                    <input
                                        name="cellId"
                                        placeholder="Enter Cell Id"
                                        className={`form-control`}
                                        ref={register}
                                    />

                                </FormGroup>
                                <FormGroup>
                                    <Label for="cellId" style={{ lineHeight: 1.2 }}>
                                        Registration Count<br/>
                                        <small className="text-reddit" style={{opacity: 0.65}}>
                                            If a value other than 0 is specified, the antifraud calculation will be
                                            performed according to the "Allow" type. Upon reaching the total number
                                            of registrations, the calculation will be based on the type specified
                                            in the configuration.
                                        </small>
                                    </Label>
                                    <input
                                        name="afTypeSwitchCount"
                                        placeholder="Registration count"
                                        className={`form-control`}
                                        defaultValue={0}
                                        ref={register}
                                    />

                                </FormGroup>
                            </>
                        ) :
                            (
                                <>
                                    <FormGroup>
                                        <Label for="project">Project</Label>
                                        <Input type="select" name="project" className="mb-2" id="project" value={project}
                                            onChange={e => handleProjectChange(e.target.value)}>
                                            {
                                                esb.projects.map(type => <option key={type}>{type}</option>)
                                            }
                                        </Input>
                                    </FormGroup>
                                </>
                            )
                    }

                    <FormGroup>
                        <Label for="type">Type</Label>
                        <Input type="select" name="type" className="mb-2" id="type" value={type}
                            onChange={e => handleTypeChange(e.target.value)}>
                            {
                                types.map(type => <option key={type}>{type}</option>)
                            }
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Row className="mt-3 align-items-center">
                            <Col xs="auto">
                                <Label>Verify Face</Label>
                            </Col>
                            <Col>
                                <Controller
                                    defaultValue={!!(props.data && props.data.verifyFace)}
                                    control={control}
                                    name="verifyFace"
                                    render={({ onChange, value }) => (
                                        <Switch onChange={(e) => onChange(e.target.checked)} value={value} />
                                        )}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" className="mr-2" onClick={() => {
                    handleSubmit(onSave)()
                }}>Save</Button>
                <Button color="secondary" onClick={onClose}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

CreateOrEdit.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    data: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}

export default CreateOrEdit
